.arrow {
  display: none;
}

.custom_checkbox {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid #000000;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &_disabled {
    &:hover {
      cursor: auto;
    }
  }

  &_checked {
    background-color: #00ca72;
    border: 2px solid #00ca72;
    color: #ffffff;
  }
}

.custom_checkbox_large {
  .custom_checkbox {
    width: 34px;
    height: 34px;

    &_checked {
      color: #ffffff;
      background-color: #00ca72;
    }
  }
}

.checkbox-wrapper {
  width: max-content;
  display: flex;
  align-items: center;
}
