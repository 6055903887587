.preScreen-block {
  height: 100vh;
  width: 100%;
  background: rgba(240, 243, 255, 0.9);

  padding: 24px 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 10%;
    align-items: center;
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    margin: 0;
    margin-top: 36px;
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    width: 240px;
  }

  .loginTitle {
    font-size: 22px;
    font-weight: 600;
    line-height: 26.4px;
    margin: 0;
    margin-top: 44px;
    color: #6161ff;
  }

  .loginText {
    font-size: 16px;
    line-height: 24px;
    margin: 6px 0 30px;
  }
}
