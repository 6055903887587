.item-modal {
  position: relative;
  width: max-content;

  &_dialog-container {
    width: 340px;
  }

  &_selected {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
  }

  &_button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
  }

  .monday-style-counter {
    padding-left: 0;
    padding-right: 0;
  }

  &_status_dialog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: max-content;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    padding: 16px;
  }

  &_title {
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
  }

  &_value {
    border: 1px solid;
    border-color: var(--ui-border-color);
    padding: 4px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:hover {
      cursor: pointer;
    }

    width: 100%;
    border-radius: 6px;
  }
}
.monday-style-tooltip {
  .monday-style-avatar_circle {
    border: none;
  }
}
