@import '../../variables.scss';

.nav {
  display: flex;
  align-items: center;
  gap: 14px;
  max-width: $containerMaxWidth;
  width: 100%;
  margin: 0 auto;

  &_item {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 12px 0 8px;
    width: max-content;

    border-radius: 38px;
    height: 40px;
    background-color: #ffffff;

    &_title {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    &-align {
      padding-left: 12px;
    }
  }

  a {
    color: #000000;
    text-decoration: none;
  }
}
