@import '../../variables.scss';

.app {
  &_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-width: $containerMaxWidth;
    position: relative;
  }

  @media (max-width: 375px) {
    .mobile-content {
      display: block;
    }
  }

  &_wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #eceff8;
    padding: 0 34px;

    width: 100%;

    .monday-style-avatar--large {
      width: 79px;
      height: 79px;
      z-index: 2;
    }
  }

  &_mobile {
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    gap: 200px;
    background-color: #f0f3ff;

    padding: 24px 30px;

    &_title {
      color: #6161ff;
      font-size: 32px;
      font-weight: 600;
      line-height: 108%;
      letter-spacing: -0.64px;
      margin: 0;
      margin-bottom: 16px;
    }

    &_text {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    &_block {
      text-align: center;
    }
  }

  @media (max-width: 820px) {
    &_mobile {
      display: flex;
    }

    &_desktop {
      display: none;
    }
  }
}

.launch-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 12px;
  gap: 24px;
  height: 100vh;
  background-color: #f0f3ff;

  &_title {
    margin: 0;
    font-size: 62px;
    font-weight: 600;
    letter-spacing: -1.257px;
    color: #6161ff;
  }

  &_text {
    font-size: 24px;
    line-height: 36px;
  }
}

.error-message {
  color: red;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
