@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.label-component--wrapper {
  display: flex;
  padding-bottom: $spacing-small;
  align-items: center;
  .label-component--icon {
    margin: 0 var(--spacing-small) 0 var(--spacing-xs);
    line-height: 24px;
    font-size: var(--font-size-general-label);
    @include theme-prop(color, icon-color);
  }
  .label-component--text {
    @include font-paragraph-bold();
    @include theme-prop(color, primary-text-color);
  }
}

.label-component--wrapper .label-component--icon{
  font-family: var(--font-family)
}
