.inline-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &_name {
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    color: #000000;
  }
}
