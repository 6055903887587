.toggle_btn {
  width: 32px;
  height: 32px;
  border: 2px solid #6161ff;
  border-radius: 50%;
  color: #6161ff;

  display: flex;
  justify-content: center;
  align-items: center;

  &_icon {
    transition: transform 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;

    &_rotate {
      transform: rotate(-180deg);
    }
  }

  &:hover {
    cursor: pointer;
  }

  &_disabled {
    color: #ebebe4;
    border-color: #ebebe4;

    &:hover {
      cursor: auto;
    }
  }
}

