.modal {
  z-index: 6;
  position: fixed;
  padding: 0 26px 0 70px;
  background-color: #6161ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  border-radius: 4px 0 0 4px;
  max-width: 454px;
  height: 100vh;
  top: 0px;
  width: 100%;

  right: -444px;
  transition: right 0.5s ease-out;

  &--open {
    right: 0;
  }

  &_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  &_description {
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
  }

  &_links {
    margin-top: 28px;

    font-size: 16px;
    line-height: 26px;

    ul {
      margin: 6px;
      padding-left: 20px;
    }

    &_link {
      gap: 6px;
      color: #ffffff;
    }
  }

  &_blocks {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &_button {
    width: 110px;
    height: 377px;
    position: absolute;
    left: -66px;
    background-image: url('../../assets/modalButtonBg.svg');
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &_hand,
    &_arrow {
      transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
      display: flex;
    }

    &_hand {
      transition: linear 0.2s;

      position: absolute;
    }

    &_arrow {
      opacity: 0;
      visibility: hidden;
    }

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px;
      border-radius: 50%;
      position: relative;
      cursor: pointer;

      &--open {
        .modal_button_hand {
          opacity: 0;
          visibility: hidden;
        }

        .modal_button_arrow {
          transform: rotate(180deg);
          opacity: 1;
          visibility: visible;
        }
      }

      &:hover {
        .modal_button_hand {
          opacity: 0;
          visibility: hidden;
        }

        .modal_button_arrow {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
