.custom-chip {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  padding: 2px 8px;
  width: max-content;

  color: #000000;

  white-space: nowrap;
}
