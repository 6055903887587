.item-modal {
  position: absolute;
  background-color: #fff;
  width: 500px;
  height: 100vh;
  top: 0px;
  right: 0;
  position: fixed;
  scrollbar-color: #6161ff transparent;
  scrollbar-width: thin;
  z-index: 10;

  overflow-y: scroll;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: var(--ui-border-color);
  border-radius: 16px 0 0 16px;

  &_header {
    border-bottom: 1px solid;
    border-color: var(--ui-border-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_header,
  &_content {
    padding: 8px 32px;
    width: 100%;
  }

  &_content {
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #6161ff;
}

::-webkit-scrollbar-track {
  background: #6161ff;
}
