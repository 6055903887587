.block {
  padding: 12px 18px 14px 14px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  background: #ffffff;

  text-decoration: none;
  color: #000000;

  &:hover {
    cursor: pointer;
    box-shadow: var(--box-shadow-small);
  }

  &_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin-left: 10px;
  }

  &_link {
    color: #6161ff;
    text-decoration: none;
    gap: 8px;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  &_wrapper,
  &_labels {
    display: flex;
    gap: 8px;
  }

  &_btn {
    cursor: pointer;
  }
}
