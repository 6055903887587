.section {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;

  &_title {
    margin: 0;
    line-height: 68px;
    font-weight: 600;
    font-size: 62.826px;
    letter-spacing: -1.257px;
    color: #6161ff;
    max-width: 750px;
    font-style: normal;
  }

  &_description {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: 16px;
    max-width: 750px;
  }

  &_content {
    margin-top: 24px;
  }
}
