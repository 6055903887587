@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.monday-style-split-button {
  display: inline-flex;
  align-items: center;
  border-radius: $border-radius-small;
  transition: var(--motion-productive-short) transform, var(--motion-productive-medium) var(--motion-timing-transition) min-width;

  .monday-style-split-button__main-button {
    margin-right: 0;
  }

  .monday-style-split-button__secondary-button {
    margin-left: 0;
  }

  &__secondary-button-wrapper {
    .monday-style-split-button__secondary-button-icon-wrapper {
      display: inline-flex;
      padding: 0 4px;
    }
  }

  &--active {
    transform: scale(0.95);
  }

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &--kind {
    &-tertiary {
      &.monday-style-split-button {
        &--color {
          &-primary {
            &.monday-style-split-button--main-active {
              .monday-style-split-button__main-button {
                background-color: var(--primary-selected-color);
              }

              .monday-style-split-button__secondary-button {
                background-color: var(--primary-selected-color);
              }

              &.monday-style-split-button--split-content-open,
              &.monday-style-split-button--hovered {
                &.monday-style-split-button--split-content-open {
                  .monday-style-split-button__secondary-button,
                  .monday-style-split-button__secondary-button:hover {
                    color: var(--primary-color);
                  }
                }
              }
            }
          }
          &-positive {
            &.monday-style-split-button--split-content-open,
            &:hover,
            &:focus {
              .monday-style-split-button__secondary-button {
                border-color: transparent;

                @include theme-prop(background-color, positive-color-selected);
              }

              .monday-style-split-button__main-button {
                @include theme-prop(background-color, positive-color-selected);
              }
            }
          }

          &-negative {
            &.monday-style-split-button--split-content-open,
            &:hover,
            &:focus {
              .monday-style-split-button__secondary-button {
                border-color: transparent;

                @include theme-prop(background-color, negative-color-selected);
              }

              .monday-style-split-button__main-button {
                @include theme-prop(background-color, negative-color-selected);
              }
            }
          }
        }
      }

      .monday-style-split-button__secondary-button {
        border-left: 1px solid transparent;
        margin-left: 1px;
      }
    }

    &-primary {
      &.monday-style-split-button--disabled {
        .monday-style-split-button__secondary-button {
          border-left: 1px solid;

          @include theme-prop(border-color, ui-border-color);
        }
      }

      &:not(.monday-style-split-button--disabled).monday-style-split-button {
        &--color {
          &-primary {
            &.monday-style-split-button--main-active {
              .monday-style-split-button__secondary-button {
                background-color: var(--primary-hover-color);
                border-color: var(--text-color-on-primary)
              }
            }
            .monday-style-split-button__secondary-button {
              border-left: 1px solid;

              @include theme-prop(border-color, primary-hover-color);
            }
          }

          &-positive {
            .monday-style-split-button__secondary-button {
              border-left: 1px solid;

              @include theme-prop(border-color, positive-color-hover);
            }
          }

          &-negative {
            .monday-style-split-button__secondary-button {
              border-left: 1px solid;

              @include theme-prop(border-color, negative-color-hover);
            }
          }

          &-on-primary-color {
            .monday-style-split-button__secondary-button {
              border-left: 1px solid;
              border-color: var(--color-ui_grey);
            }
          }
        }
      }
    }

    &-secondary {
      .monday-style-split-button__main-button {
        border-right: none;
      }

      &.monday-style-split-button {
        &--color {
          &-primary {
            &.monday-style-split-button--main-active {
              .monday-style-split-button__main-button {
                background-color: var(--primary-selected-color);
              }
              .monday-style-split-button__secondary-button {
                background-color: var(--primary-selected-color);
                border-color: var(--primary-color);
              }
            }

            &.monday-style-split-button--split-content-open {
              .monday-style-split-button__secondary-button,
              .monday-style-split-button__secondary-button:hover {
                @include theme-prop(color, primary-color);
                @include theme-prop(border-color, primary-color);
                @include theme-prop(background-color, primary-selected-color);
              }
            }
          }
        }
      }
    }
  }
}
