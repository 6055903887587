.progress-bar {
  padding: 42px 20px 60px 40px;
  background: #efefef;

  &_title {
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
  }

  &_percent {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    margin-right: 4px;
  }

  &_indicator,
  &_wrapper {
    border-radius: 32px;
    height: 18px;
  }

  &_wrapper {
    position: relative;
    width: 100%;
    background: #d9d9d9;
  }

  &_indicator {
    position: absolute;
    left: 0;
    background-color: #00ca72;
  }
}
