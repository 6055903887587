.ritual {
  padding: 32px;
  background-color: #ffffff;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 12px;

  min-width: 350px;
  max-width: 356px;

  &_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &_description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  &_wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &_schedule {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  &_label {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 290px;
  }
}
