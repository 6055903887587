.custom-accordion {
  width: 100%;
  background: #ffffff;
  border-radius: 24px;

  padding: 12px 14px 14px 14px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin-left: 10px;
  }

  &_body {
    margin-left: 32px;
  }

  &_description {
    display: inline-block;
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  &_content {
    margin-top: 16px;
  }
}
