.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;

    background-color: #6161ff;
    color: #ffffff;

    font-size: 15.683px;
    font-weight: 400;
    border-radius: 34px;

    padding: 8px 34px 8px 8px;

    &:hover {
      cursor: pointer;
    }
  }
}
