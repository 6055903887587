@import "../../styles/themes";
@import "../../styles/typography";
@import "../../styles/global-css-settings";

.monday-style-attention-box-component {
  position: relative;
  padding: var(--spacing-medium);
  border-radius: var(--border-radius-medium);
  font-size: var(--font-size-general-label);
  width: fit-content;
  height: fit-content;
  @include theme-prop(color, dark-color);

  &__title {
    margin: 0;
  }

  &_text--compact {
    display: flex;
    align-items: center;
    padding-right: var(--spacing-medium);
  }
  &__text,
  &__title-container {
    @include theme-prop(color, primary-text-color);
  }
  &--type {
    &-primary {
      @include theme-prop(color, primary-text-color);
      @include theme-prop(background-color, primary-selected-color);

      &__title-container {
        &__icon {
          @include theme-prop(color, primary-color);
        }
      }
    }

    &-danger {
      @include theme-prop(background-color, negative-color-selected);

      &__title-container {
        &__icon {
          @include theme-prop(color, negative-color);
        }
      }
    }

    &-success {
      @include theme-prop(background-color, positive-color-selected);

      &__title-container {
        &__icon {
          @include theme-prop(color, positive-color);
        }
      }
    }

    &-dark {
      @include theme-prop(background-color, primary-background-hover-color);
      @include theme-prop(color, primary-text-color);

      &__title-container {
        &__icon {
          @include theme-prop(color, primary-text-color);
        }
      }
    }
  }

  &__title-container {
    margin-bottom: var(--spacing-small);

    &:empty {
      margin-bottom: 0;
    }

    &__icon {
      margin-right: var(--spacing-xs);
    }
  }

  &__close-icon {
    &.monday-style-attention-box-component__close-icon {
      color: var(--primary-text-color);
    }
    &--wrapper {
      position: absolute;
      top: $spacing-small-medium;
      right: $spacing-small-medium;
    }
    &--compact {
      top: calc(50% - 16px);
    }
  }
}

.monday-style-attention-box-component {
  font-family: var(--font-family);
}

.monday-style-attention-box-component__title-container__title {
  font-family: var(--title-font-family);
}
