@import '../../variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 36px;
  height: $headerHeight;
  width: 100%;
  max-width: 1300px;

  &_avatar-wrapper {
    position: relative;
    margin-left: 32px;
    z-index: 3;

    &:hover {
      cursor: pointer;
    }
  }

  &_container {
    position: absolute;
    bottom: -72px;
    right: 0px;
    width: max-content;
    padding: 0px;

    box-shadow: 0px 6px 60px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  &_signOut {
    padding: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &_person {
    margin-top: 4px;
    width: 40px;
    height: 40px;
    position: relative;

    &_box {
      display: flex;
      align-items: center;
    }

    &_tooltip {
      position: absolute;
      right: 48px;
      border-radius: 4px;
      width: max-content;
      padding: 10px;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      background-color: #6161ff;
      display: flex;
      align-items: center;
      transition: transform 0.3s;

      a {
        color: #ffffff;
        text-decoration: none;
      }

      &:hover {
        cursor: pointer;
        transform: translateX(-6px);
      }

      &::after {
        content: '';
        position: absolute;
        right: -5px;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        background-color: #6161ff;
      }
    }
  }

  &_logo {
    margin-top: 10px;
  }
  .monday-style-avatar--medium {
    width: 40px;
    height: 40px;
  }
}

.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f6f7fb;
  z-index: 5;

  padding: 0 40px;
  background: linear-gradient(180deg, #f6f7fb 0%, rgba(246, 247, 251, 0) 76.84%);
  backdrop-filter: blur(2px);

  display: flex;
  align-items: center;
  justify-content: center;
}
