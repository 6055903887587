.media-card {
  position: relative;
  min-width: 356px;
  max-width: 356px;
  height: 328px;
  background-color: inherit;

  &_wrapper {
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;

    &_checkbox {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &_img {
      border-radius: 24px;
      width: 342px;
      height: 180px;
      margin: 8px 6px 0 6px;
      object-fit: cover;
    }

    &_box {
      padding: 16px 22px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 16px;
      height: 40%;
    }

    &_title {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &_labels {
      display: flex;
      gap: 8px;
      overflow: hidden;
      width: 274px;
    }
  }

  &_bg-figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 354px;
    height: 328px;
    background-color: #fff;
    clip-path: path(
      'M356 32C356 14.3269 341.673 0 324 0H32C14.3269 0 0 14.3269 0 32V295C0 312.673 14.3269 327 32 327H296C305.217 327 312.721 319.664 312.992 310.513C312.997 310.509 313 310.505 313 310.5V309.5C313 295.417 324.417 284 338.5 284H339.5C339.606 284 339.712 283.994 339.819 283.981C348.251 283.581 355.075 277.038 355.913 268.727C355.97 268.384 356 268.03 356 267.664V32Z'
    );
  }

  &_link {
    text-decoration: none;
  }
}
