.circle-bar {
  width: 24px;
  height: 24px;
  border: 2px solid #6161ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &_completed {
    border-color: #00ca72;
    background-color: #00ca72;
    color: #ffffff;
  }
}
