.modal-card {
  &_wrapper {
    padding-top: 12px;
    padding-right: 18px;
    position: relative;
  }

  &_block {
    padding: 20px 16px 16px 26px;
    display: flex;
    width: 328px;
    height: 172px;
    flex-direction: column;

    background-image: url('../../assets/modalCardBg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom left;

    &_title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    &_name {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }

    &_position {
      font-size: 16px;
      line-height: 24px;
      height: 24px;
      margin-top: 2px;
    }

    &_title,
    &_name,
    &_position {
      max-width: 216px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &_link {
      color: #ffffff;
      gap: 8px;
      text-decoration: none;
      margin-top: 28px;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    }

    &_avatar {
      position: absolute;
      right: 10px;
      top: 0;
    }
  }
}

