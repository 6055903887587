.contact-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  min-width: 364px;
  max-width: 364px;
  height: 322px;
  position: relative;
  padding: 12px 18px 0 0;

  &_bg {
    background-image: url('../../assets/meetingCardBg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;

    width: 100%;
    height: 100%;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
    height: 100%;
    padding: 28px 0 30px 28px;

    &_avatar {
      position: absolute;
      right: 0;
      top: 0;
    }

    &_title,
    &_position {
      max-width: 244px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &_title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #cfcff9;
    }

    &_name {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin: 8px 0;
      color: #ffffff;

      max-width: 244px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &_position {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
      color: #ffffff;
      height: 24px;
    }

    &_btn-wrapper {
      display: flex;
      gap: 16px;
      cursor: pointer;
      margin-bottom: 20px;
      height: 30px;
    }

    &_btn {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      width: 32px;
      transition: width 0.8s;

      a {
        text-decoration: none;
        color: #cfcff9;
      }

      &_icon,
      &_link {
        display: flex;
        opacity: 1;
        transition: opacity 1s ease;
      }

      &_link {
        opacity: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 10px;
        border: 1px solid #cfcff9;
        border-radius: 64px;
        color: #cfcff9;
        width: max-content;
        position: absolute;
      }

      &:hover {
        width: 124px;

        .contact-card_wrapper_btn_link {
          opacity: 1;
        }

        .contact-card_wrapper_btn_icon {
          opacity: 0;
        }
      }
    }

    &_link {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #cfcff9;
      text-decoration: none;
      gap: 4px;
      z-index: 2;
    }

    &_labels {
      display: flex;
      gap: 8px;
      width: 294px;
      overflow: hidden;
    }

    &_bottom-box {
      margin-top: auto;
    }
  }
}
