.labels-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  width: 200px;
  &:hover {
    cursor: pointer;
  }

  &_name {
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    color: #ffffff;
  }
}
