@import '../../variables.scss';

.main {
  background-color: inherit;
  padding: 24px 0 54px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 104px;
  margin-top: $headerHeight;
  max-width: $containerMaxWidth;

  &_card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 18px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
  }

  &_col-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
