.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  max-width: 1440px;
  margin: 0 auto;
  padding: 32px 0 24px;

  &_right-side {
    display: flex;
    gap: 24px;
  }

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
