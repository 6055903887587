@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');
@import './variables.scss';
html,
body {
  scroll-behavior: smooth;
  scroll-padding-top: $headerHeight;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}
